import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { PageContext } from "@util/types";
import { Component, Hero } from "@components";
import FooterSections from "@shared/footer/footerSections";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

export default function PageTemplate({ data, pageContext }: Props) {
  if (!data.sanityPage) return null;

  const { pageMeta, components } = data.sanityPage;

  if (!pageMeta) return null;

  return (
    <>
      <SEO seoData={pageMeta.seo} slug={pageContext.pagePath} />
      <Hero data={pageMeta.pageHero} />
      {components &&
        components.map(component => {
          if (component == null) return null;
          return (
            <Component key={component._key} data={component} />
          );
        })
      }
      <FooterSections pageFooter={pageMeta.pageFooter} />
    </>
  );
}

export const query = graphql`
  query PageQuery($slug: String) {
    sanityPage(pageMeta: {slug: {current: {eq: $slug}}}) {
      pageMeta {
        ...sanityPageMeta
      }
      components {
        ...sanityComponents
      }
    }
  }
`;
